<template>
  <div>
    <Input
      label="Firma Adı *"
      v-model="form.companyName"
      :defaultValue="selectedItem.companyName"
      :state="validateState('companyName')"
    />
    <Input
      label="Email *"
      v-model="form.email"
      :defaultValue="selectedItem.email"
      :state="validateState('email')"
    />
    <Input
      label="Telefon"
      v-model="form.phone"
      :defaultValue="selectedItem.phone"
    />
    <Checkbox
      formGroupLabel="Durum *"
      label="Aktif"
      v-model="form.isActive"
      :defaultValue="
        selectedItem.isActive === true || selectedItem.isActive === false
          ? selectedItem.isActive.toString()
          : ''
      "
    />
  </div>
</template>

<script>
// Utils
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'InsertUpdateOperations',
  mixins: [validationMixin],
  props: {
    confirmModal: Boolean,
    selectedItem: String | Object,
  },
  data() {
    return {
      loading: false,
      form: {
        companyName: '',
        email: '',
        phone: '',
        isActive: true,
      },
    };
  },
  validations: {
    form: {
      companyName: {
        required,
      },
      email: {
        required,
        email,
      },
      isActive: {
        required,
      },
    },
  },
  created() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    confirm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.$emit('resetInsertUpdateForm', false);
        return;
      }
      let payload = {
        companyName: this.form.companyName,
        email: this.form.email,
        isActive: this.form.isActive,
      };

      if (this.form.phone) payload.phone = this.form.phone;

      this.$dbFunctions
        .insertOrUpdate(`company`, this.selectedItem.id, payload)
        .then((res) => {
          this.$dbFunctions.setResponse(this, res);
          this.$emit('resetInsertUpdateForm', true);
          this.formReset();
        })
        .catch((err) => {
          this.$emit('resetInsertUpdateForm', false);
          this.$dbFunctions.setResponse(this, err);
        })
        .finally(() => {});
    },
    formReset() {
      Object.keys(this.form).forEach((key) => (this.form[key] = ''));
      this.$v.$reset();
    },
  },
  watch: {
    confirmModal(newVal) {
      if (newVal) {
        this.confirm();
      }
    },
    selectedItem(newVal) {
      this.formReset();
    },
  },
};
</script>
